import React, { useState } from "react";

import { RiShieldStarFill, RiShieldFlashFill } from "react-icons/ri";
import { FaShieldHeart } from "react-icons/fa6";
import { BsShieldFillCheck } from "react-icons/bs";
import { FaStarHalfStroke } from "react-icons/fa6";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Table = () => {
  const TableData = [
    {
      serial: "#1",
      icon1: "M",
      name1: "Mera Sharma",
      number1: 123,
     
    },
    {
      serial: "#2",
      icon1: "A",
      name1: "Amit",
      number1: 123,
    
    },
    {
      serial: "#3",
      icon1: "G",
      name1: "Geeta",
      number1: 123,
      
    },
    {
      serial: "#4",
      icon1: "K",
      name1: "Karan",
      number1: 123,
      
    },
    {
      serial: "#5",
      icon1: "T",
      name1: "Tanya Sharma",
      number1: 123,
      
    },
    {
      serial: "#6",
      icon1: "C",
      name1: "Chandni",
      number1: 123,
      
    },
    {
      serial: "#7",
      icon1: "B",
      name1: "Babita",
      number1: 123,
      
    },
    {
      serial: "#8",
      icon1: "E",
      name1: "Era",
      number1: 123,
    },
    {
      serial: "#9",
      icon1: "F",
      name1: "Farid",
      number1: 123,
    },
  ];

  const Data = [
    {
      ladel: "Most Valuable Players",
      icon: <RiShieldStarFill className="fs-4 text-info me-2" />,
    },
    {
      ladel: "Customer wow champion",
      icon: <FaShieldHeart className="fs-4 text-danger me-2" />,
    },

    {
      ladel: "Sharpshooter",
      icon: <RiShieldFlashFill className="fs-4 text-warning me-2" />,
    },
    {
      ladel: "Speed racer",
      icon: <BsShieldFillCheck className="fs-4 text-primary me-2" />,
    },
  ];

  const [formValues, setFormValues] = useState({
    totalcall: "",
    // totalcallduration: "",
    connectedcall: "",
    averagecallduration: "",
  });

  const [response, setResponse] = useState([]);
  const navigate = useNavigate();


  // console.log(typeof(response))


  // Object.entries(response).map(e => e[1])


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {

    
    e.preventDefault();
    
    // Headers
    
    const config = {
      headers: {
          'Authorization': 'Bearer YOUR_TOKEN', // Replace with your actual token
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Custom-Header': 'Custom Value', // Add your custom headers here
          'X-Version': '1.0.0', // Add your version here
          'X-Language': 'en-US', // Add your language here
          
        },
      };
      
    // console.log(formValues);

  // Api post Form data

    try {
      const res = await axios.post(
        "https://95bd-2401-4900-8820-43c1-c15d-2dec-9fcf-67e8.ngrok-free.app/data",
        formValues,config
      );
      let getdata = [res?.data?.received]
//       const transformedData = Object?.entries(getdata[0])?.map(([name, value]) => ({
//         name: name,
//         value: value[0].toFixed()
//       }));

//       const highestEntry = transformedData.reduce((max, item) => (item.value > max.value ? item : max), transformedData[0]);

// console.log(highestEntry);

const transformedData = Object.entries(getdata[0])
.map(([name, value]) => ({
  name: name,
  value: parseFloat(value[0].toFixed())  // Ensure value is treated as a number
}))
.sort((a, b) => b.value - a.value);  // Sort in descending order

console.log(transformedData);
      
      setResponse(transformedData);

      toast.success("Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/");

    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <>
      <div className="side-button mt-4">
        <button
          className="btn"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          <FaStarHalfStroke className="me-1 mb-1" />
          Rank
        </button>
      </div>

      <div className="container">
        <div className="row">
          {Data?.map((data, i) => (
            <div className="col-md-3 col-sm-2 mt-2">
              <div className="d-flex">
                {data.icon}
                <h6>{data.ladel}</h6>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          {response?.map((link, i) => (
            <div className="col-md-3 col-sm-2 mt-3">
              <div className="d-flex align-items-center me-auto">
                <div
                  className={`${getStatusColor(
                    link.name1
                  )} rounded-3 px-3 py-2 me-3 text-secondary`}
                >
                  {link?.name[0]}
                </div>
                <div className="list-content">
                  <div>{link?.name}</div>
                  <div className="text-secondary">{link?.value}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <!-- Modal --> */}
      <form onSubmit={handleSubmit}>
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered ">
            <div class="modal-content box-shadow">
              <div class="modal-header">
                <div
                  class="modal-title fs-4 text-secondary"
                  id="staticBackdropLabel"
                >
                  Weight
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center mb-3">
                      <h4 className="me-3">Total Call :-</h4>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="form__group field">
                        <input
                          type="input"
                          class="form__field"
                          name="totalcall"
                          value={formValues.input1}
                          onChange={handleChange}
                        />
                        {/* <label for="name" class="form__label">
                        WeightAge
                      </label> */}
                      </div>
                    </div>
                   
                    
                    <div className="col-md-6 d-flex align-items-center mb-3">
                      <h4 className="me-3">Connected Call :-</h4>
                      {/* <h5>200</h5> */}
                    </div>
                    <div class="col-md-6">
                      <div class="form__group field">
                        <input
                          type="input"
                          class="form__field"
                          name="connectedcall"
                          value={formValues.connectedcall}
                          onChange={handleChange}
                        />
                       
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center mb-3">
                      <h4 className="me-3">Average Call Duration :-</h4>
                    </div>
                    <div class="col-md-6">
                      <div class="form__group field">
                        <input
                          type="input"
                          class="form__field"
                          name="averagecallduration"
                          value={formValues.averagecallduration}
                          onChange={handleChange}
                        />
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn-danger rounded-3 py-1 me-4 px-4"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn-primary rounded-3 py-1 px-4" data-bs-dismiss="modal">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default Table;

function getStatusColor(name1) {
  const firstLetter = name1?.charAt(0);
  switch (firstLetter) {
    case "A":
      return "list-box1";
    case "B":
      return "list-box2";
    case "C":
      return "list-box3";
    case "D":
      return "list-box4";
    case "E":
      return "list-box5";
    case "F":
      return "list-box7";
    case "G":
      return "list-box6";
    case "H":
      return "list-box8";
    case "I":
      return "list-box9";
    case "J":
      return "list-box1";
    case "K":
      return "list-box7";
    case "L":
      return "list-box2";
    case "M":
      return "list-box8";
    case "N":
      return "list-box3";
    case "O":
      return "list-box4";
    case "P":
      return "list-box5";
    case "Q":
      return "list-box6";
    case "R":
      return "list-box9";
    case "S":
      return "list-box1";
    case "T":
      return "list-box9";
    case "U":
      return "list-box3";
    case "V":
      return "list-box4";
    case "W":
      return "list-box6";
    case "X":
      return "list-box5";
    case "Y":
      return "list-box7";
    default:
      return "list-box4";
  }
}
