import React from "react";

import { RiShieldStarFill } from "react-icons/ri";

const List = () => {
  const ListData = [
    {
      title: "Heading 1",
      icon1: "T",
      name1: "Tanya Sharma",
      number1: 123,
    },
    {
      title: "Heading 2",
      icon1: "P",
      name1: "Pooja",
      number1: 123,
    },
    {
      title: "Heading 3",
      icon1: "P",
      name1: "Pooja",
      number1: 123,
    },
    {
      title: "Heading 4",
      icon1: "P",
      name1: "Pooja",
      number1: 123,
    },
    {
      title: "Heading 1",
      icon1: "T",
      name1: "Tanya Sharma",
      number1: 123,
    },
    {
      title: "Heading 2",
      icon1: "P",
      name1: "Pooja",
      number1: 123,
    },
    {
      title: "Heading 3",
      icon1: "P",
      name1: "Pooja",
      number1: 123,
    },
    {
      title: "Heading 4",
      icon1: "P",
      name1: "Pooja",
      number1: 123,
    },
  ];
  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="d-flex align-items-center py-3">
              <RiShieldStarFill className="fs-1 text-info me-3" />
              <h4>Heading</h4>
            </div>

            <ol className="list-group list-group-numbered">
              {ListData.map((link, index) => (
                <li className="d-flex align-items-center mb-3">
                  <a
                    className="text-decoration-none text-dark"
                    href="/#"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <div className="d-flex align-items-center ms-4 me-auto">
                      <div className="list-box rounded-3 px-3 py-2 me-3 text-secondary">
                        {link.icon1}
                      </div>
                      <div className="list-content">
                        <div>{link.name1}</div>
                        <div className="text-secondary">{link.number1}</div>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ol>
          </div>
          <div className="col-lg-6">
            <div className="d-flex align-items-center py-3">
              <RiShieldStarFill className="fs-1 text-info me-3" />
              <h4>Heading</h4>
            </div>
            <ol className="list-group">
              {ListData.map((link, index) => (
                <li className="d-flex align-items-center mb-3">
                  <a
                    className="text-decoration-none text-dark"
                    href="/#"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <div className="d-flex align-items-center ms-4 me-auto">
                      <div className="list-box rounded-3 px-3 py-2 me-3 text-secondary">
                        {link.icon1}
                      </div>
                      <div className="list-content">
                        <div>{link.name1}</div>
                        <div className="text-secondary">{link.number1}</div>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </section>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Form
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <h4 className="me-3">Total Call :-</h4>
                    <h5>300</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="form__group field">
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        required=""
                      />
                      <label for="name" class="form__label">
                        WeightAge
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                    <h4 className="me-3">Total Call duration :-</h4>
                    <h5>5 Hours</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="form__group field">
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        required=""
                      />
                      <label for="name" class="form__label">
                        WeightAge
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                    <h4 className="me-3">Connected Call :-</h4>
                    <h5>200</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="form__group field">
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        required=""
                      />
                      <label for="name" class="form__label">
                        WeightAge
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                    <h4 className="me-3">Average Call Duration :-</h4>
                    <h5>3 Hours</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="form__group field">
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        required=""
                      />
                      <label for="name" class="form__label">
                        WeightAge
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default List;
