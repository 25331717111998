// import logo from './logo.svg';
// import { Button } from 'bootstrap';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import List from "./component/pages/list";
import Table from "./component/pages/table";
// import Component1 from "./component/pages/usecontext";

function App() {
  return (
    <>
    <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Table />} />
          <Route path="/list" element={<List />} />
        </Routes>
      </Router>
    </>

  );
}

export default App;
